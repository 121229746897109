var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "avatar", style: _vm.style },
    [!_vm.avatar ? [_vm._v(_vm._s(_vm.initials))] : _vm._e()],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }