import Vue from 'vue';
export default Vue.extend({
    inheritAttrs: false,
    props: {
        value: {
            type: String,
            default: '',
        },
    },
    methods: {
        onInput: function (value) {
            this.$emit('input', value);
        },
    },
});
