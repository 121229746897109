import Vue from 'vue';
var TYPES = {
    pdf: ['.pdf'],
    jpg: ['.jpg', '.jpeg', '.jpe', '.jfif-tbnl', '.jfif'],
    png: ['.png'],
};
var DEFAULT_TYPE = 'file';
export default Vue.extend({
    props: {
        name: {
            type: String,
            required: true,
        },
    },
    computed: {
        iconName: function () {
            var _this = this;
            var type = (this.name && Object.keys(TYPES)
                .find(function (key) { return TYPES[key].some(function (v) { return _this.name.endsWith(v); }); })) || DEFAULT_TYPE;
            return "format-" + type;
        },
    },
});
