import { getHttp } from 'shared/utils/http';
export var getRequestsList = function (params) { return (getHttp().get({
    path: '/user-api/api/hospitalRegistrationRequests',
    params: params,
})); };
export var getRequestById = function (id) { return (getHttp().get({
    path: "/user-api/api/hospitalRegistrationRequests/" + id,
})); };
export var processRequest = function (options) { return (getHttp().patch({
    path: "/user-api/api/hospitalRegistrationRequests/" + options.id + "/" + options.action,
})); };
export var getRegisteredList = function (params) { return (getHttp().get({
    path: '/user-api/api/hospitals',
    params: params,
})); };
export var getRegisteredById = function (id) { return (getHttp().get({
    path: "/user-api/api/hospitals/" + id,
})); };
export var toggleIsActive = function (id) { return (getHttp().patch({
    path: "/user-api/api/hospitals/" + id + "/state",
})); };
