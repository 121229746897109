import Vue from 'vue';
import { isObject } from '@lyra_npm/lyra.ui.components/utils/validators.js';
import redirectToLogin from 'shared/utils/redirectToLogin';
export var isAuthError = function (_a) {
    var _b = _a.status, status = _b === void 0 ? 0 : _b;
    return [401, 419].includes(status);
};
var handleError = function (error, onInvalidFields) {
    var _a, _b, _c, _d, _e;
    var notify = Vue.prototype.$notify || console;
    if (!isObject(error)) {
        notify.error('Error');
        console.error(error); // eslint-disable-line no-console
        return;
    }
    var invalidFields = ((_a = error.data) === null || _a === void 0 ? void 0 : _a.mvsInvalidFields) || ((_b = error.data) === null || _b === void 0 ? void 0 : _b.errors); // TODO: remove fallback
    if (isAuthError(error)) {
        redirectToLogin();
    }
    else if (error.status === 500 && ((_c = error.data) === null || _c === void 0 ? void 0 : _c.mvsLogMark)) {
        notify.error(error.status + " " + error.statusText + "<br>logMark: " + error.data.mvsLogMark);
    }
    else if (invalidFields && Object.keys(invalidFields).length && onInvalidFields) {
        onInvalidFields(invalidFields);
    }
    else {
        notify.error(((_d = error.data) === null || _d === void 0 ? void 0 : _d.mvsErrorMessage) || ((_e = error.data) === null || _e === void 0 ? void 0 : _e.mvsServerMessage) || error.statusText || error.message || 'Error');
        console.error(error); // eslint-disable-line no-console
    }
};
export default (function (vm) {
    // eslint-disable-next-line no-param-reassign
    vm.prototype.$handleError = handleError;
});
