import { __assign } from "tslib";
import commonMessages from './messages';
export default {
    name: {
        required: true,
        maxlength: 50,
        messages: commonMessages,
    },
    surname: {
        required: true,
        maxlength: 50,
        messages: commonMessages,
    },
    login: {
        required: true,
        type: 'email',
        maxlength: 254,
        messages: __assign(__assign({}, commonMessages), { typeMismatch: 'Login does not meet the requirements. Enter e-mail' }),
    },
    birthDate: {
        min: '1900-01-01 00:00:00',
        max: new Date().toISOString(),
        messages: __assign(__assign({}, commonMessages), { badInput: 'Invalid date', rangeOverflow: "Date is over max: " + new Date().toLocaleDateString() }),
    },
    description: {
        maxlength: 255,
    },
    password: {
        required: true,
        maxlength: 64,
        minlength: 8,
        messages: __assign(__assign({}, commonMessages), { tooShort: 'Password must contain 8 to 64 characters' }),
    },
};
