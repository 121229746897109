import Vue from 'vue';
var STATES = {
    pending: {
        className: 'state--neutral',
    },
    approved: {
        className: 'state--positive',
    },
    rejected: {
        className: 'state--negative',
        text: 'refused',
    },
    error: {
        className: 'state--negative',
    },
};
export default Vue.extend({
    props: {
        state: {
            type: String,
            required: true,
        },
    },
    computed: {
        text: function () {
            var _a;
            var state = this.state.toLowerCase();
            return ((_a = STATES[state]) === null || _a === void 0 ? void 0 : _a.text) || state;
        },
        className: function () {
            var _a;
            return (_a = STATES[this.state.toLowerCase()]) === null || _a === void 0 ? void 0 : _a.className;
        },
    },
});
