import Vue from 'vue';
import { LyraList } from '@lyra_npm/lyra.ui.components';
import { getList } from 'shared/provider/api/admins';
import formatDate from 'filters/formatDate';
var MAX_CONTENT = 'max-content';
export default Vue.extend({
    components: { LyraList: LyraList },
    filters: { formatDate: formatDate('DD.MM.YYYY') },
    data: function () {
        return {
            items: [],
            isFetching: false,
            columns: [
                {
                    label: 'Login',
                    field: 'login',
                },
                {
                    label: 'Description',
                    field: 'description',
                },
                {
                    label: 'Disabled',
                    field: 'isEnabled',
                    size: MAX_CONTENT,
                },
                {
                    label: 'Deactivated',
                    field: 'isActive',
                    size: MAX_CONTENT,
                },
                {
                    label: 'Created',
                    field: 'createDate',
                    sortable: true,
                    size: MAX_CONTENT,
                },
                {
                    label: 'Modified',
                    field: 'updateDate',
                    size: MAX_CONTENT,
                },
            ],
        };
    },
    watch: {
        $route: 'fetchData',
    },
    created: function () {
        this.fetchData();
    },
    methods: {
        fetchData: function () {
            var _this = this;
            this.isFetching = true;
            getList()
                .then(function (items) {
                _this.items = items;
            })
                .catch(function (e) { return _this.$handleError(e); })
                .finally(function () {
                _this.isFetching = false;
            });
        },
        openEdit: function (_a) {
            var id = _a.id;
            return { name: 'administrators.edit', params: { id: id } };
        },
    },
});
