import { __assign } from "tslib";
import Vue from 'vue';
import VTabs from 'components/VNavigationTabs/component.vue';
import { countriesMapper } from 'store/modules/countries';
export default Vue.extend({
    components: {
        VTabs: VTabs,
    },
    data: function () {
        return {
            title: 'Hospitals',
            tabs: [
                { label: 'Registered', path: '/hospitals/registered' },
                { label: 'Requests', path: '/hospitals/requests' },
            ],
        };
    },
    created: function () {
        this.fetchCountries()
            .catch(this.$handleError);
    },
    methods: __assign({}, countriesMapper.mapActions(['fetchCountries'])),
});
