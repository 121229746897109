import VBase from 'index/pages/prohibited-videos/index.vue';
import { getCDNStreamsList as getListAPI, deleteCDNStreamItem as deleteItemAPI, deleteAllCDNStreams as deleteAllAPI, } from 'shared/provider/api/content';
export default VBase.extend({
    data: function () {
        return {
            contentType: 'Stream',
        };
    },
    methods: {
        getListAPI: getListAPI,
        deleteItemAPI: deleteItemAPI,
        deleteAllAPI: deleteAllAPI,
    },
});
