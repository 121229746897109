var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app__inner" },
    [
      _c("lyra-list", {
        attrs: {
          items: _vm.requests.items,
          columns: _vm.columns,
          "is-fetching": _vm.isFetching,
          "row-link": _vm.openEdit,
          "sorted-by": "createDate",
          "sort-direction": 1,
          "empty-message": "No data"
        },
        scopedSlots: _vm._u([
          {
            key: "layout.fetching",
            fn: function() {
              return [_c("lyra-spinner")]
            },
            proxy: true
          },
          {
            key: "login",
            fn: function(ref) {
              var row = ref.row
              return [[_vm._v(_vm._s(row.user.login))]]
            }
          },
          {
            key: "name",
            fn: function(ref) {
              var row = ref.row
              return [[_vm._v(_vm._s(_vm.formatUserName(row.user)))]]
            }
          },
          {
            key: "createDate",
            fn: function(ref) {
              var item = ref.item
              return [[_vm._v(_vm._s(_vm._f("formatDate")(item)))]]
            }
          },
          {
            key: "state",
            fn: function(ref) {
              var item = ref.item
              return [_c("v-request-state", { attrs: { state: item } })]
            }
          }
        ])
      }),
      _vm.pagination
        ? _c(
            "lyra-pagination",
            _vm._b(
              {
                attrs: { "inactive-button-params": { tone: "light" } },
                on: { changePage: _vm.setPageNumber }
              },
              "lyra-pagination",
              _vm.pagination,
              false
            ),
            [
              _c("v-icon", {
                attrs: { slot: "prev", name: "arrow-left", type: "small-60" },
                slot: "prev"
              }),
              _c("v-icon", {
                attrs: { slot: "next", name: "arrow-right", type: "small-60" },
                slot: "next"
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "transition",
        { attrs: { name: "dialog-transition" } },
        [_c("router-view")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }