var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return Object.keys(_vm.fields).length
    ? _c(
        "lyra-dialog",
        {
          staticClass: "lyra-dialog--ungap",
          on: { close: _vm.onClose },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function() {
                  return [
                    _c("h2", { staticClass: "lyra-dialog__title" }, [
                      _vm._v(_vm._s(_vm.fields.name))
                    ]),
                    _c(
                      "lyra-tooltip",
                      { attrs: { "append-to-body": "" } },
                      [
                        _c(
                          "lyra-button",
                          {
                            staticClass: "lyra-dialog__close",
                            attrs: {
                              type: "button",
                              theme: "neutral",
                              tone: "light",
                              shape: "circle",
                              size: "micro"
                            },
                            on: { click: _vm.onClose }
                          },
                          [_c("v-icon", { attrs: { name: "close" } })],
                          1
                        ),
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [_vm._v("Close")]
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "footer",
                fn: function() {
                  return [
                    _c("div", { staticClass: "form__additional" }, [
                      _c("div", [
                        _vm._v(
                          "Created " +
                            _vm._s(
                              _vm._f("relativeDate")(_vm.fields.createDate)
                            )
                        )
                      ]),
                      _c("div", [
                        _vm._v(
                          "Modified " +
                            _vm._s(
                              _vm._f("relativeDate")(_vm.fields.updateDate)
                            )
                        )
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "form__controls" },
                      [
                        _c(
                          "lyra-tooltip",
                          { staticClass: "lyra-tooltip--gapped" },
                          [
                            _c(
                              "lyra-button",
                              {
                                attrs: {
                                  theme: "neutral",
                                  tone: "light",
                                  shape: "circle",
                                  size: "small"
                                },
                                on: { click: _vm.toggleIsActive }
                              },
                              [
                                _c("v-icon", {
                                  attrs: {
                                    name: _vm.fields.isActive
                                      ? "deactivate"
                                      : "restore"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [_vm._v(_vm._s(_vm.statusLabels.isActive))]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            3075356907
          )
        },
        [
          [
            _c("dl", { staticClass: "form__list form__list--single" }, [
              _c("dt", { staticClass: "form__list-label" }, [_vm._v("Owner")]),
              _c(
                "dd",
                { staticClass: "form__list-item" },
                [_c("v-user", { attrs: { user: _vm.owner } })],
                1
              )
            ]),
            _c("hr"),
            _c(
              "dl",
              { staticClass: "form__list" },
              [
                _c("dt", { staticClass: "form__list-label" }, [_vm._v("Name")]),
                _c("dd", { staticClass: "form__list-item" }, [
                  _vm._v(_vm._s(_vm.fields.name))
                ]),
                _c("dt", { staticClass: "form__list-label" }, [
                  _vm._v("Country")
                ]),
                _c("dd", { staticClass: "form__list-item" }, [
                  _vm._v(_vm._s(_vm.countryName))
                ]),
                _c("dt", { staticClass: "form__list-label" }, [_vm._v("City")]),
                _c("dd", { staticClass: "form__list-item" }, [
                  _vm._v(_vm._s(_vm.fields.city))
                ]),
                _c("dt", { staticClass: "form__list-label" }, [
                  _vm._v("Description")
                ]),
                _c("dd", { staticClass: "form__list-item" }, [
                  _vm._v(_vm._s(_vm.fields.description))
                ]),
                _c("v-form-line-link", {
                  attrs: { "web-page": _vm.fields.webPage }
                })
              ],
              1
            )
          ]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }