import Vue from 'vue';
import redirectToLogin from 'shared/utils/redirectToLogin';
export default Vue.extend({
    data: function () {
        return {
            links: [
                {
                    to: '/',
                    text: 'Dashboard',
                    icon: 'dashboard',
                    exact: true,
                },
                {
                    to: '/users',
                    text: 'Users',
                    icon: 'user',
                },
                {
                    to: '/administrators',
                    text: 'Administrators',
                    icon: 'admin',
                },
                {
                    to: '/hospitals',
                    text: 'Hospitals',
                    icon: 'hospital',
                },
                {
                    to: '/business-profiles',
                    text: 'Business Profiles',
                    icon: 'business',
                },
                {
                    to: '/prohibited-videos',
                    text: 'Prohibited Videos',
                    icon: 'content',
                },
                {
                    to: '/prohibited-streams',
                    text: 'Prohibited Streams',
                    icon: 'content',
                },
            ],
        };
    },
    methods: {
        logout: function () {
            redirectToLogin();
        },
    },
});
