import { __assign } from "tslib";
import Vue from 'vue';
import debounce from 'lodash/debounce';
import { LyraList, LyraPagination } from '@lyra_npm/lyra.ui.components';
import VSearch from 'components/VSearch/component.vue';
import formatDate from 'filters/formatDate';
import { getList } from 'shared/provider/api/users';
import filterConstraints from 'shared/constraints/filter';
import formatUserName from 'index/utils/formatUserName';
var MAX_CONTENT = 'max-content';
var DEFAULT_PARAMS = {
    limit: 20,
    pageNumber: 0,
    sortBy: 'createDate',
    sortOrder: 'desc',
};
export default Vue.extend({
    components: {
        LyraList: LyraList,
        LyraPagination: LyraPagination,
        VSearch: VSearch,
    },
    filters: { formatDate: formatDate('DD.MM.YYYY') },
    data: function () {
        return {
            filterConstraints: filterConstraints,
            users: {
                items: [],
                pageCount: 0,
            },
            params: __assign(__assign({}, DEFAULT_PARAMS), { filter: '' }),
            isFetching: false,
            columns: [
                { field: 'login', label: 'E-mail', innerClass: 'lyra-list__crop' },
                { field: 'name', label: 'Name', sortable: true },
                { field: 'description', label: 'Description', sortable: true },
                {
                    field: 'isPlatformModerator', label: 'Moderator', size: MAX_CONTENT, sortable: true,
                },
                {
                    field: 'isVerified', label: 'Verified', size: MAX_CONTENT, sortable: true,
                },
                {
                    field: 'isEnabled', label: 'Disabled', size: MAX_CONTENT, sortable: true,
                },
                {
                    field: 'isActive', label: 'Deactivated', size: MAX_CONTENT, sortable: true,
                },
                {
                    field: 'createDate', label: 'Created', size: MAX_CONTENT, sortable: true,
                },
                {
                    field: 'updateDate', label: 'Modified', size: MAX_CONTENT, sortable: true,
                },
            ],
        };
    },
    computed: {
        pagination: function () {
            var pageCount = this.users.pageCount;
            if (!pageCount || pageCount <= 1) {
                return null;
            }
            return {
                total: pageCount,
                current: this.params.pageNumber + 1,
            };
        },
    },
    watch: {
        $route: 'fetchData',
        params: {
            deep: true,
            handler: debounce(function () {
                this.fetchData();
            }, 300),
        },
    },
    created: function () {
        this.fetchData();
    },
    methods: {
        formatUserName: formatUserName,
        openEdit: function (_a) {
            var id = _a.id;
            return { name: 'user.edit', params: { id: id } };
        },
        fetchData: function () {
            var _this = this;
            this.isFetching = true;
            getList(this.params)
                .then(function (response) {
                _this.users.items = response.data;
                _this.users.pageCount = response.pageCount;
            })
                .catch(function (e) { return _this.$handleError(e); })
                .finally(function () {
                _this.isFetching = false;
            });
        },
        setPageNumber: function (pageNumber) {
            this.params.pageNumber = pageNumber - 1;
        },
        setFilter: function (filter) {
            this.params = __assign(__assign({}, this.params), { filter: filter, sortBy: DEFAULT_PARAMS.sortBy, sortOrder: DEFAULT_PARAMS.sortOrder, pageNumber: DEFAULT_PARAMS.pageNumber });
        },
        onSortChange: function (_a) {
            var direction = _a.direction, field = _a.field;
            this.params = __assign(__assign({}, this.params), { sortBy: field, sortOrder: direction === 1 ? 'desc' : 'asc', pageNumber: DEFAULT_PARAMS.pageNumber });
        },
    },
});
