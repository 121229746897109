var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "lyra-base-input password-change" }, [
    _c("div", { staticClass: "lyra-base-input__label" }, [_vm._v("Password")]),
    _c(
      "div",
      { staticClass: "lyra-base-input__wrapper" },
      [
        _c(
          "lyra-button",
          {
            staticClass: "password-change__button",
            attrs: { theme: "primary", tone: "light" },
            on: { click: _vm.openDialog }
          },
          [_vm._v("Change password")]
        ),
        _c(
          "transition",
          { attrs: { name: "dialog-transition" } },
          [
            _vm.showDialog
              ? _c(
                  "lyra-dialog",
                  {
                    staticClass: "password-change__dialog",
                    on: { close: _vm.hideDialog },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function() {
                            return [
                              _c("h2", { staticClass: "lyra-dialog__title" }, [
                                _vm._v("Change password")
                              ])
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "footer",
                          fn: function() {
                            return [
                              _c(
                                "lyra-button",
                                {
                                  attrs: {
                                    theme: "primary",
                                    shape: "rect",
                                    tone: "light",
                                    border: ""
                                  },
                                  on: { click: _vm.cancel }
                                },
                                [_vm._v("Cancel")]
                              ),
                              _c(
                                "lyra-button",
                                {
                                  attrs: {
                                    theme: "primary",
                                    shape: "rect",
                                    form: "change-password",
                                    type: "submit"
                                  }
                                },
                                [_vm._v("Change")]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1187475977
                    )
                  },
                  [
                    [
                      _c(
                        "form",
                        {
                          attrs: {
                            id: "change-password",
                            novalidate: "",
                            autocomplete: "off"
                          },
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.change($event)
                            }
                          }
                        },
                        [
                          _c(
                            "lyra-input-password",
                            _vm._b(
                              {
                                ref: "fieldPassword",
                                attrs: {
                                  "is-new": "",
                                  generator: _vm.generator,
                                  generateButtonProps: _vm.buttonProps
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "after",
                                      fn: function() {
                                        return [
                                          _vm._v(_vm._s(_vm.afterMessage))
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  423369147
                                ),
                                model: {
                                  value: _vm.password,
                                  callback: function($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password"
                                }
                              },
                              "lyra-input-password",
                              _vm.constraints,
                              false
                            )
                          )
                        ],
                        1
                      )
                    ]
                  ],
                  2
                )
              : _vm._e()
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }