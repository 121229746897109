import handleFieldsErrorMixin from 'mixins/handleFieldsError';
import { processRequest, getRequestsById, } from 'shared/provider/api/users';
import relativeDate from 'filters/relativeDate';
import VUser from 'components/VUser/component.vue';
import VFileType from 'components/VFileType/component';
import VFormLineLink from 'components/VFormLineLink/component';
import VRequestState from 'components/VRequestState/component.vue';
import formatUserName from 'index/utils/formatUserName';
var NOTIFICATION = {
    accept: 'Verification request approved',
    reject: 'Verification request refused',
};
export default handleFieldsErrorMixin.extend({
    filters: {
        relativeDate: relativeDate,
    },
    components: {
        VUser: VUser,
        VFileType: VFileType,
        VRequestState: VRequestState,
        VFormLineLink: VFormLineLink,
    },
    data: function () {
        return {
            fields: {},
        };
    },
    computed: {
        id: function () {
            return this.$route.params.id;
        },
        nameFormatted: function () {
            return formatUserName(this.fields.user);
        },
    },
    created: function () {
        this.fetchData();
    },
    methods: {
        fetchData: function () {
            var _this = this;
            getRequestsById(this.id)
                .then(function (response) {
                _this.fields = response;
            })
                .catch(function (error) {
                _this.$handleError(error);
                _this.onClose();
            });
        },
        process: function (action) {
            var _this = this;
            var process = function () {
                processRequest(_this.id, action)
                    .then(function () { _this.$notify.success(NOTIFICATION[action]); })
                    .catch(function (error) { return _this.$handleError(error); })
                    .finally(function () { return _this.onClose(); });
            };
            if (!this.fields.user.isVerified || action !== 'reject') {
                process();
                return;
            }
            this.$confirm({
                header: 'Refuse the request?',
                message: "User <b>" + this.nameFormatted + "</b> is already verified. After refusal, the verification status will be deleted.",
                type: 'negative',
                submitText: 'Refuse',
            })
                .then(function () { return process(); })
                .catch(function () { }); // eslint-disable-line @typescript-eslint/no-empty-function
        },
        onClose: function () {
            this.$router.push({ name: 'users.requests' });
        },
    },
});
