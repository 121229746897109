import Vue from 'vue';
export default Vue.extend({
    components: {
        VCopy: function () { return import(/* webpackChunkName: "v-copy" */ 'components/VCopy/component.vue'); },
    },
    props: {
        id: {
            type: String,
            default: '',
        },
        webPage: {
            type: String,
            default: '',
        },
        login: {
            type: String,
            default: '',
        },
        phoneNumber: {
            type: String,
            default: '',
        },
    },
    computed: {
        linkHref: function () {
            return (this.webPage
                || (this.id && this.$config.userWebUrl + "/en/u/" + this.id)
                || (this.login && "mailto:" + this.login)
                || (this.phoneNumber && "tel:" + this.phoneNumber)
                || '');
        },
        linkDisplayed: function () {
            return (this.login
                || this.phoneNumber
                || this.linkHref);
        },
        label: function () {
            return ((this.webPage && 'Site')
                || (this.id && 'Account Link')
                || (this.login && 'E-mail')
                || (this.phoneNumber && 'Phone'));
        },
        copy: function () {
            return ((this.login && { text: this.login, notification: 'E-mail copied' })
                || (this.phoneNumber && { text: this.phoneNumber, notification: 'Phone copied' })
                || null);
        },
    },
});
