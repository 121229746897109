import dayjs from 'dayjs';
export default {
    income: function (isoString) {
        if (!isoString) {
            return {
                date: '',
                month: '',
                year: '',
            };
        }
        var date = new Date(isoString);
        return {
            date: date.getDate(),
            month: date.getMonth(),
            year: date.getFullYear(),
        };
    },
    outcome: function (object) {
        var date = object.date, month = object.month, year = object.year;
        if (!date && !month && !year) {
            return null;
        }
        var dateObj = new Date(Number(year), Number(month), Number(date));
        return dayjs(dateObj).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
    },
};
