var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "lyra-dialog",
    {
      on: { close: _vm.onClose },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c("h2", { staticClass: "lyra-dialog__title" }, [
                _vm._v("Create administrator")
              ]),
              _c(
                "lyra-tooltip",
                { attrs: { "append-to-body": "" } },
                [
                  _c(
                    "lyra-button",
                    {
                      staticClass: "lyra-dialog__close",
                      attrs: {
                        type: "button",
                        theme: "neutral",
                        tone: "light",
                        shape: "circle",
                        size: "micro"
                      },
                      on: { click: _vm.onClose }
                    },
                    [_c("v-icon", { attrs: { name: "close" } })],
                    1
                  ),
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _vm._v("Close")
                  ])
                ],
                1
              )
            ]
          },
          proxy: true
        },
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "lyra-button",
                {
                  attrs: {
                    theme: "primary",
                    shape: "rect",
                    type: "submit",
                    form: _vm._uid
                  }
                },
                [_vm._v("Create")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      [
        _c(
          "form",
          {
            staticClass: "form",
            attrs: { id: _vm._uid, novalidate: "" },
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.onSubmit($event)
              }
            }
          },
          [
            _c(
              "lyra-input",
              _vm._b(
                {
                  ref: "fieldLogin",
                  staticClass: "form__element lyra-base-input--required",
                  model: {
                    value: _vm.fields.login,
                    callback: function($$v) {
                      _vm.$set(_vm.fields, "login", $$v)
                    },
                    expression: "fields.login"
                  }
                },
                "lyra-input",
                _vm.constraints.login,
                false
              ),
              [_vm._v("Login")]
            ),
            _c(
              "lyra-input-password",
              _vm._b(
                {
                  ref: "fieldPassword",
                  staticClass: "form__element lyra-base-input--required",
                  attrs: {
                    "is-new": "",
                    generator: _vm.generatePassword,
                    generateButtonProps: _vm.buttonProps
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "after",
                      fn: function() {
                        return [_vm._v("8-16 digits or latin characters.")]
                      },
                      proxy: true
                    }
                  ]),
                  model: {
                    value: _vm.fields.password,
                    callback: function($$v) {
                      _vm.$set(_vm.fields, "password", $$v)
                    },
                    expression: "fields.password"
                  }
                },
                "lyra-input-password",
                _vm.constraints.password,
                false
              ),
              [_vm._v("Password")]
            ),
            _c(
              "lyra-input",
              _vm._b(
                {
                  ref: "fieldDescription",
                  staticClass: "form__element",
                  model: {
                    value: _vm.fields.description,
                    callback: function($$v) {
                      _vm.$set(_vm.fields, "description", $$v)
                    },
                    expression: "fields.description"
                  }
                },
                "lyra-input",
                _vm.constraints.description,
                false
              ),
              [_vm._v("Description")]
            ),
            _c(
              "lyra-input-flag",
              {
                staticClass: "form__element",
                attrs: { reversed: "" },
                model: {
                  value: _vm.fields.isEnabled,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "isEnabled", $$v)
                  },
                  expression: "fields.isEnabled"
                }
              },
              [_vm._v("Enabled")]
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }