import { render, staticRenderFns } from "./component.vue?vue&type=template&id=8ba0e168&lang=pug&"
import script from "./component.vue?vue&type=script&lang=ts&"
export * from "./component.vue?vue&type=script&lang=ts&"
import style0 from "./component.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8ba0e168')) {
      api.createRecord('8ba0e168', component.options)
    } else {
      api.reload('8ba0e168', component.options)
    }
    module.hot.accept("./component.vue?vue&type=template&id=8ba0e168&lang=pug&", function () {
      api.rerender('8ba0e168', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/index/components/TheSidebar/component.vue"
export default component.exports