var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-icon",
    _vm._b({ attrs: { name: _vm.iconName } }, "v-icon", _vm.$attrs, false)
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }