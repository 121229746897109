var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c(
        "div",
        { staticClass: "avatar__wrapper" },
        [
          _c("v-avatar", _vm._b({}, "v-avatar", _vm.user, false)),
          _c(
            "div",
            { staticClass: "avatar__name" },
            [
              _vm._v(_vm._s(_vm.nameFormatted)),
              _vm.user.isVerified
                ? _c("v-icon", {
                    attrs: { name: "verified", type: "primary", float: "after" }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }