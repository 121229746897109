var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app__wrapper" },
    [
      _c(
        "div",
        { staticClass: "app__title-wrapper" },
        [
          _c("h1", { staticClass: "app__title" }, [_vm._v("Administrators")]),
          _c(
            "router-link",
            {
              staticClass: "lyra-button",
              class: ["primary", "rect", "small"].map(function(m) {
                return "lyra-button--" + m
              }),
              attrs: { to: { name: "administrators.create" } }
            },
            [
              _c("v-icon", { attrs: { name: "plus", float: "included" } }),
              _vm._v("Add")
            ],
            1
          )
        ],
        1
      ),
      _c("lyra-list", {
        attrs: {
          columns: _vm.columns,
          isFetching: _vm.isFetching,
          items: _vm.items,
          rowLink: _vm.openEdit,
          sortedBy: "createDate",
          sortDirection: 1,
          emptyMessage: "No data"
        },
        scopedSlots: _vm._u([
          {
            key: "isEnabled",
            fn: function(ref) {
              var item = ref.item
              return [
                !item
                  ? _c("v-icon", {
                      attrs: { name: "disabled", type: "neutral" }
                    })
                  : _vm._e()
              ]
            }
          },
          {
            key: "isActive",
            fn: function(ref) {
              var item = ref.item
              return [
                !item
                  ? _c("v-icon", {
                      attrs: { name: "deactivate", type: "neutral" }
                    })
                  : _vm._e()
              ]
            }
          },
          {
            key: "createDate",
            fn: function(ref) {
              var item = ref.item
              return [_vm._v(_vm._s(_vm._f("formatDate")(item)))]
            }
          },
          {
            key: "updateDate",
            fn: function(ref) {
              var item = ref.item
              return [_vm._v(_vm._s(_vm._f("formatDate")(item)))]
            }
          },
          {
            key: "layout.fetching",
            fn: function() {
              return [_c("lyra-spinner")]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "transition",
        { attrs: { name: "dialog-transition" } },
        [_c("router-view")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }