var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tabs" },
    [
      _c("div", { staticClass: "tabs__active-slider", style: _vm.sliderStyle }),
      _vm._l(_vm.tabs, function(tab) {
        return _c(
          "router-link",
          {
            key: tab.id,
            ref: "tab",
            refInFor: true,
            staticClass: "tabs__item",
            attrs: { to: tab.path }
          },
          [_vm._v(_vm._s(tab.label))]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }