var TOKEN_NAME = 'accessToken';
var TOKEN_EXP_NAME = 'accessTokenExp';
var REFRESH_TOKEN_NAME = 'refreshToken';
export default {
    get: function () {
        return localStorage.getItem(TOKEN_NAME);
    },
    clear: function () {
        localStorage.removeItem(TOKEN_NAME);
        localStorage.removeItem(TOKEN_EXP_NAME);
        localStorage.removeItem(REFRESH_TOKEN_NAME);
    },
};
