import Vue from 'vue';
export default Vue.extend({
    props: {
        tabs: {
            type: Array,
            required: true,
        },
    },
    data: function () {
        return {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            removeRouterListener: function () { },
            width: 0,
            left: 0,
        };
    },
    computed: {
        sliderStyle: function () {
            return {
                width: this.width + "px",
                'margin-left': this.left + "px",
            };
        },
    },
    created: function () {
        var _this = this;
        this.removeRouterListener = this.$router.afterEach(function () {
            _this.calcActiveTabWidth();
        });
    },
    mounted: function () {
        var _this = this;
        this.calcActiveTabWidth();
        // fixes bug with incorrect element size calculation if font has not been loaded yet
        window.onload = function () {
            window.onload = null;
            _this.calcActiveTabWidth();
        };
    },
    beforeDestroy: function () {
        this.removeRouterListener();
        window.onload = null;
    },
    methods: {
        calcActiveTabWidth: function () {
            var _this = this;
            var activeTabIndex = this.tabs.findIndex(function (tab) { return _this.$route.path.includes(tab.path); });
            if (activeTabIndex === -1) {
                return;
            }
            var el = this.$refs.tab[activeTabIndex].$el;
            this.width = el.offsetWidth;
            this.left = el.offsetLeft;
        },
    },
});
