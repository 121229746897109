import Vue from 'vue';
import SilentRefresh from '@lyra_npm/lyra.ui.components/utils/SilentRefresh';
import App from 'App.vue';
import router from 'router/index';
import store from 'store/index';
import redirectToLogin from 'shared/utils/redirectToLogin';
import loginByRefreshToken from 'shared/provider/api/refresh';
import getConfig from 'shared/utils/getConfig';
import registerGlobals from './registerGlobals';
getConfig().then(function (config) {
    registerGlobals(config);
    var silentRefresh = new SilentRefresh({
        onLogoutCallback: redirectToLogin,
        loginByRefreshToken: function (_a) {
            var refreshToken = _a.refreshToken, accessToken = _a.accessToken;
            return (loginByRefreshToken({ adminId: accessToken.sub, refreshToken: refreshToken }));
        },
    });
    silentRefresh.afterLogin();
    // eslint-disable-next-line no-new
    new Vue({
        el: '#app',
        router: router,
        store: store,
        render: function (h) {
            return h(App);
        },
    });
});
