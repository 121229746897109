import handleFieldsErrorMixin from 'mixins/handleFieldsError';
import generatePassword from 'shared/utils/generatePassword';
export default handleFieldsErrorMixin.extend({
    props: {
        handler: {
            type: Function,
            required: true,
        },
        constraints: {
            type: Object,
            required: true,
        },
    },
    data: function () {
        return {
            showDialog: false,
            password: '',
            generator: generatePassword,
            buttonProps: {
                theme: 'primary',
                tone: 'light',
            },
        };
    },
    computed: {
        afterMessage: function () {
            return this.constraints.minlength + "-" + this.constraints.maxlength + " digits or latin characters.";
        },
    },
    methods: {
        openDialog: function () {
            this.showDialog = true;
        },
        hideDialog: function () {
            this.showDialog = false;
        },
        cancel: function () {
            this.password = '';
            this.hideDialog();
        },
        change: function () {
            var _this = this;
            if (this.isFormInvalid()) {
                return;
            }
            this.handler(this.password)
                .then(function () {
                _this.cancel();
            })
                .catch(function (e) {
                _this.handleError(e);
            });
        },
    },
});
