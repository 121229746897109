import Vue from 'vue';
import 'shared/icons/index.js';
var CLASS_NAME = 'icon';
export default Vue.extend({
    props: {
        name: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: '',
        },
        float: {
            type: String,
            default: '',
        },
    },
    computed: {
        classes: function () {
            var _a;
            return _a = {},
                _a[CLASS_NAME] = true,
                _a[CLASS_NAME + "--" + this.type] = this.type,
                _a[CLASS_NAME + "--" + this.color] = this.color,
                _a[CLASS_NAME + "--" + this.float] = this.float,
                _a;
        },
    },
});
