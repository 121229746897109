import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from 'pages/dashboard.vue';
import Users from 'pages/users/index.vue';
import UsersAll from 'pages/users/all/index.vue';
import UserCreate from 'pages/users/all/create.vue';
import UserEdit from 'pages/users/all/_id.vue';
import UsersRequests from 'pages/users/requests/index.vue';
import UserRequest from 'pages/users/requests/_id.vue';
import Administrators from 'pages/administrators/index.vue';
import AdministratorsEdit from 'pages/administrators/_id.vue';
import AdministratorsCreate from 'pages/administrators/create.vue';
import Hospitals from 'pages/hospitals/index.vue';
import HospitalsRequests from 'pages/hospitals/requests/index.vue';
import HospitalRequest from 'pages/hospitals/requests/_id.vue';
import HospitalsRegistered from 'pages/hospitals/registered/index.vue';
import HospitalRegistered from 'pages/hospitals/registered/_id.vue';
import BusinessProfiles from 'pages/business-profiles/index.vue';
import BusinessProfilesRegistered from 'pages/business-profiles/registered/index.vue';
import BusinessProfileRegistered from 'pages/business-profiles/registered/_id.vue';
import BusinessProfilesRequests from 'pages/business-profiles/requests/index.vue';
import BusinessProfileRequest from 'pages/business-profiles/requests/_id.vue';
import ProhibitedVideos from 'pages/prohibited-videos/index.vue';
import ProhibitedStreams from 'pages/prohibited-streams/index.vue';
Vue.use(VueRouter);
var routes = [
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
    },
    {
        path: '/users',
        name: 'users',
        component: Users,
        redirect: 'users/all',
        children: [
            {
                path: 'all',
                name: 'users.all',
                component: UsersAll,
                children: [
                    {
                        path: 'create',
                        name: 'user.create',
                        component: UserCreate,
                    },
                    {
                        path: ':id',
                        name: 'user.edit',
                        component: UserEdit,
                    },
                ],
            },
            {
                path: 'requests',
                name: 'users.requests',
                component: UsersRequests,
                children: [
                    {
                        path: ':id',
                        name: 'user.request',
                        component: UserRequest,
                    },
                ],
            },
        ],
    },
    {
        path: '/administrators',
        name: 'administrators',
        component: Administrators,
        children: [
            {
                path: 'create',
                name: 'administrators.create',
                component: AdministratorsCreate,
            },
            {
                path: ':id',
                name: 'administrators.edit',
                component: AdministratorsEdit,
            },
        ],
    },
    {
        path: '/hospitals',
        component: Hospitals,
        redirect: '/hospitals/registered',
        children: [
            {
                path: 'registered',
                name: 'hospitals.registered',
                component: HospitalsRegistered,
                children: [
                    {
                        path: ':id',
                        name: 'hospital.registered',
                        component: HospitalRegistered,
                    },
                ],
            },
            {
                path: 'requests',
                name: 'hospitals.requests',
                component: HospitalsRequests,
                children: [
                    {
                        path: ':id',
                        name: 'hospital.request',
                        component: HospitalRequest,
                    },
                ],
            },
        ],
    },
    {
        path: '/business-profiles',
        component: BusinessProfiles,
        redirect: '/business-profiles/registered',
        children: [
            {
                path: 'registered',
                name: 'business-profiles.registered',
                component: BusinessProfilesRegistered,
                children: [
                    {
                        path: ':id',
                        name: 'business-profile.registered',
                        component: BusinessProfileRegistered,
                    },
                ],
            },
            {
                path: 'requests',
                name: 'business-profiles.requests',
                component: BusinessProfilesRequests,
                children: [
                    {
                        path: ':id',
                        name: 'business-profile.request',
                        component: BusinessProfileRequest,
                    },
                ],
            },
        ],
    },
    {
        path: '/prohibited-videos',
        component: ProhibitedVideos,
    },
    {
        path: '/prohibited-streams',
        component: ProhibitedStreams,
    },
    {
        path: '*',
        redirect: '/',
    },
];
export default new VueRouter({
    mode: 'history',
    routes: routes,
});
