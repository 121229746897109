import VBase from 'index/pages/hospitals/requests/_id';
import { getRequestById, processRequest, } from 'shared/provider/api/business-profiles';
export default VBase.extend({
    data: function () {
        return {
            fields: {
                userAccount: {},
            },
            closeRouteName: 'business-profiles.requests',
        };
    },
    methods: {
        getRequestByIdProvider: getRequestById,
        processRequestProvider: processRequest,
    },
});
