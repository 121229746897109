import { __extends } from "tslib";
import queryString from 'query-string';
var createUrl = function (baseUrl, relativeUrl, params) {
    var url = relativeUrl
        ? baseUrl.replace(/\/+$/, '') + "/" + relativeUrl.replace(/^\/+/, '')
        : baseUrl;
    var query = params ? queryString.stringify(params) : '';
    return query ? url + "?" + query : url;
};
var HttpError = /** @class */ (function (_super) {
    __extends(HttpError, _super);
    function HttpError(description) {
        var _this = _super.call(this, description.statusText) || this;
        _this.data = description.data;
        _this.status = description.status;
        _this.statusText = description.statusText;
        return _this;
    }
    return HttpError;
}(Error));
var processResponse = function (response) { return new Promise(function (resolve, reject) {
    response.json()
        .then(function (parsed) {
        if (response.ok) {
            resolve(parsed);
        }
        else {
            reject(new HttpError({
                status: response.status,
                statusText: response.statusText,
                data: parsed,
            }));
        }
    })
        .catch(function (error) {
        console.error('Failed to transform response to json', error);
        if (response.ok) {
            resolve('');
        }
        else {
            reject(new HttpError({
                status: response.status,
                statusText: response.statusText,
            }));
        }
    });
}); };
export default (function (_a) {
    var baseUrl = _a.baseUrl, mode = _a.mode, getHeaders = _a.getHeaders;
    var request = function (requestArguments) { return new Promise(function (resolve, reject) {
        var path = requestArguments.path, method = requestArguments.method, body = requestArguments.body, params = requestArguments.params;
        var url = createUrl(baseUrl, path, params);
        var options = {
            method: method.toUpperCase(),
            mode: mode,
            headers: getHeaders({ method: method }),
        };
        if (body) {
            options.body = JSON.stringify(body);
        }
        fetch(url, options)
            .then(processResponse)
            .then(resolve)
            .catch(reject);
    }); };
    var requestFactory = function (method) { return function (_a) {
        var path = _a.path, params = _a.params, body = _a.body;
        return (request({
            method: method, path: path, params: params, body: body,
        }));
    }; };
    return {
        get: requestFactory('get'),
        post: requestFactory('post'),
        put: requestFactory('put'),
        delete: requestFactory('delete'),
        patch: requestFactory('patch'),
    };
});
