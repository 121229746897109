var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app__wrapper" },
    [
      _c("div", { staticClass: "app__title-wrapper" }, [
        _c("h1", { staticClass: "app__title" }, [_vm._v("Users")]),
        _c(
          "div",
          { staticClass: "app__title-controls" },
          [
            _c("portal-target", { attrs: { name: "search", slim: "" } }),
            _c(
              "router-link",
              {
                staticClass: "lyra-button",
                class: ["primary", "rect", "small"].map(function(m) {
                  return "lyra-button--" + m
                }),
                attrs: { to: { name: "user.create" } }
              },
              [
                _c("v-icon", { attrs: { name: "plus", float: "included" } }),
                _vm._v("Add")
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("v-tabs", { attrs: { tabs: _vm.tabs } }),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }