import { __assign } from "tslib";
import Vue from 'vue';
import debounce from 'lodash/debounce';
import { LyraList, LyraPagination } from '@lyra_npm/lyra.ui.components';
import { getRequestsList } from 'shared/provider/api/users';
import formatDate from 'filters/formatDate';
import VRequestState from 'components/VRequestState/component.vue';
import formatUserName from 'index/utils/formatUserName';
var SELF_ROUTE = {
    name: 'users.requests',
};
var CHILD_ROUTE = {
    name: 'user.request',
};
var DEFAULT_PARAMS = {
    limit: 20,
    pageNumber: 0,
};
export default Vue.extend({
    components: {
        LyraList: LyraList,
        LyraPagination: LyraPagination,
        VRequestState: VRequestState,
    },
    filters: {
        formatDate: formatDate('DD.MM.YYYY'),
    },
    data: function () {
        return {
            requests: {
                items: [],
                pageCount: 0,
            },
            isFetching: false,
            params: __assign({}, DEFAULT_PARAMS),
            columns: [
                { field: 'login', label: 'E-mail' },
                { field: 'name', label: 'Name' },
                { field: 'createDate', label: 'Created', sortable: true },
                { field: 'state', label: 'Status' },
            ],
        };
    },
    computed: {
        pagination: function () {
            var pageCount = this.requests.pageCount;
            if (!pageCount || pageCount <= 1) {
                return null;
            }
            return {
                total: pageCount,
                current: this.params.pageNumber + 1,
            };
        },
    },
    watch: {
        params: {
            deep: true,
            handler: debounce(function () {
                this.fetchData();
            }, 300),
        },
        $route: function () {
            if (this.$route.name === SELF_ROUTE.name) {
                this.fetchData();
            }
        },
    },
    mounted: function () {
        this.fetchData();
    },
    methods: {
        formatUserName: formatUserName,
        fetchData: function () {
            var _this = this;
            this.isFetching = true;
            getRequestsList(this.params)
                .then(function (response) {
                _this.requests = response;
            })
                .catch(this.$handleError)
                .finally(function () {
                _this.isFetching = false;
            });
        },
        setPageNumber: function (pageNumber) {
            this.params.pageNumber = pageNumber - 1;
        },
        openEdit: function (request) {
            return __assign(__assign({}, CHILD_ROUTE), { params: {
                    id: request.id,
                } });
        },
    },
});
