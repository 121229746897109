import { create } from 'shared/provider/api/admins';
import constraints from 'shared/constraints/administrator';
import handleFieldsErrorMixin from 'mixins/handleFieldsError';
import generatePassword from 'shared/utils/generatePassword';
export default handleFieldsErrorMixin.extend({
    data: function () {
        return {
            fields: {
                login: '',
                description: '',
                password: '',
                isEnabled: true,
            },
            constraints: constraints,
            buttonProps: {
                theme: 'primary',
                tone: 'light',
            },
        };
    },
    methods: {
        generatePassword: generatePassword,
        onClose: function () {
            this.$router.push({ name: 'administrators' });
        },
        onSubmit: function () {
            var _this = this;
            if (this.isFormInvalid()) {
                return;
            }
            create(this.fields)
                .then(function () {
                _this.$notify.success('Administrator created');
                _this.onClose();
            })
                .catch(function (error) {
                _this.handleError(error);
            });
        },
    },
});
