import VBase from 'index/pages/hospitals/requests/index';
import { getRequestsList, } from 'shared/provider/api/business-profiles';
export default VBase.extend({
    data: function () {
        return {
            requests: {
                items: [],
                pageCount: 0,
            },
            childRoute: 'business-profile.request',
            selfRoute: 'business-profiles.requests',
        };
    },
    methods: {
        getRequestsList: getRequestsList,
        // TODO: delete method, when MVSCP1-2259 will be done
        fetchData: function () {
            var _this = this;
            this.isFetching = true;
            this.getRequestsList(this.params)
                .then(function (response) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                _this.requests.items = response.items;
                _this.requests.pageCount = response.pageCount;
            })
                .catch(this.$handleError)
                .finally(function () {
                _this.isFetching = false;
            });
        },
    },
});
