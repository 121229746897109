import { isURLWithProtocols } from '@lyra_npm/lyra.ui.components/utils/validators';
import { trimSlashes } from '@lyra_npm/lyra.ui.components/utils/string';
var FALLBACK_CONFIG = {
    apiUrl: window.location.origin,
    userWebUrl: window.location.origin,
};
var getSafeApiUrl = function (url, fallback) {
    if (!url) {
        return fallback;
    }
    if (isURLWithProtocols('http', 'https')(url)) {
        return url;
    }
    if (url.startsWith('/')) {
        return fallback + url.replace(/\/+$/, '');
    }
    alert('Received invalid apiUrl configuration, fallback will be used');
    return fallback;
};
var getSafeUrl = function (url, fallback) {
    if (isURLWithProtocols('http', 'https')(url)) {
        return url;
    }
    alert('Received invalid userWebUrl configuration, fallback will be used');
    return fallback;
};
export default (function () { return new Promise(function (resolve) {
    fetch('/config.json')
        .then(function (response) { return response.json(); })
        .then(function (response) {
        resolve({
            apiUrl: trimSlashes(getSafeApiUrl((response === null || response === void 0 ? void 0 : response.apiUrl) || '', FALLBACK_CONFIG.apiUrl)),
            userWebUrl: trimSlashes(getSafeUrl((response === null || response === void 0 ? void 0 : response.userWebUrl) || '', FALLBACK_CONFIG.userWebUrl)),
        });
    })
        .catch(function (error) {
        alert('Failed to fetch configuration file, fallback will be used');
        console.dir(error);
        resolve(FALLBACK_CONFIG);
    });
}); });
