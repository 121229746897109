import { __assign } from "tslib";
import Vue from 'vue';
import VFormLineLink from 'components/VFormLineLink/component';
import VUser from 'components/VUser/component.vue';
import relativeDate from 'filters/relativeDate';
import { toggleIsActive, getRegisteredById, } from 'shared/provider/api/hospitals';
import { getSingle } from 'shared/provider/api/users';
import { countriesMapper } from 'store/modules/countries';
export default Vue.extend({
    filters: {
        relativeDate: relativeDate,
    },
    components: {
        VUser: VUser,
        VFormLineLink: VFormLineLink,
    },
    data: function () {
        return {
            fields: {},
            owner: {},
            registerType: 'Hospital',
            closeRouteName: 'hospitals.registered',
        };
    },
    computed: __assign(__assign({}, countriesMapper.mapState(['countries'])), { id: function () {
            return this.$route.params.id;
        },
        countryName: function () {
            var _this = this;
            var _a;
            return ((_a = this.countries.find(function (c) { return c.id === _this.fields.countryId; })) === null || _a === void 0 ? void 0 : _a.name) || '';
        },
        statusLabels: function () {
            return {
                isActive: this.fields.isActive ? 'Deactivate' : 'Restore',
            };
        } }),
    created: function () {
        this.fetchData();
    },
    methods: {
        toggleIsActiveProvider: toggleIsActive,
        getRegisteredByIdProvider: getRegisteredById,
        fetchData: function () {
            var _this = this;
            this.getRegisteredByIdProvider(this.id)
                .then(function (response) {
                _this.fields = response;
                return getSingle(_this.fields.owner);
            })
                .then(function (response) {
                _this.owner = response;
            })
                .catch(function (error) {
                _this.$handleError(error);
                _this.onClose();
            });
        },
        toggleIsActive: function () {
            var _this = this;
            var actionCapitalized = this.statusLabels.isActive;
            var actionLowerCased = actionCapitalized.toLowerCase();
            var registerTypeCapitalized = this.registerType;
            var registerTypeLowerCased = this.registerType.toLowerCase();
            this.$confirm({
                type: this.fields.isActive ? 'negative' : 'primary',
                header: actionCapitalized + " " + registerTypeLowerCased + "?",
                message: registerTypeCapitalized + " " + this.fields.name + " will be " + actionLowerCased + "d",
                submitText: actionCapitalized,
            })
                .then(function () { return _this.toggleIsActiveProvider(_this.id); })
                .then(function () {
                _this.$notify.success(registerTypeCapitalized + " " + actionLowerCased + "d");
                _this.fetchData();
            })
                .catch((function (err) { return err && _this.$handleError(err); }));
        },
        onClose: function () {
            this.$router.push({ name: this.closeRouteName });
        },
    },
});
