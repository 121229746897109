import { __extends } from "tslib";
/* eslint-disable max-classes-per-file */
import { Mutations, Actions, Getters, Module, createMapper, } from 'vuex-smart-module';
import fetchCountries from 'shared/provider/api/countries';
var CountriesState = /** @class */ (function () {
    function CountriesState() {
        this.countries = [];
    }
    return CountriesState;
}());
var CountriesGetters = /** @class */ (function (_super) {
    __extends(CountriesGetters, _super);
    function CountriesGetters() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return CountriesGetters;
}(Getters));
var CountriesMutations = /** @class */ (function (_super) {
    __extends(CountriesMutations, _super);
    function CountriesMutations() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CountriesMutations.prototype.setCountries = function (response) {
        this.state.countries = response;
    };
    return CountriesMutations;
}(Mutations));
var CountriesActions = /** @class */ (function (_super) {
    __extends(CountriesActions, _super);
    function CountriesActions() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CountriesActions.prototype.fetchCountries = function () {
        var _this = this;
        return fetchCountries().then(function (response) {
            _this.commit('setCountries', response);
        });
    };
    return CountriesActions;
}(Actions));
var countries = new Module({
    namespaced: true,
    state: CountriesState,
    mutations: CountriesMutations,
    actions: CountriesActions,
});
export default countries;
export var countriesMapper = createMapper(countries);
