import { __assign } from "tslib";
import constraints from 'shared/constraints/user';
import relativeDate from 'filters/relativeDate';
import mixin from 'mixins/handleFieldsError';
import { getSingle, edit, updateStatus, updatePassword, } from 'shared/provider/api/users';
import mapBirthDate from 'shared/provider/mapper/birthDate';
import PasswordChange from 'components/VPasswordChange/component.vue';
import VTags from 'components/VTags/component.vue';
import VFormLineLink from 'components/VFormLineLink/component';
import formatUserName from 'utils/formatUserName';
var MONTH_NAMES = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
];
var FLAGS = {
    isPlatformModerator: {
        label: ['Entrust Moderator Role', 'Recall Moderator Role'],
        confirmMessage: [
            'User <b>{userName}</b> profile will be granted moderator rights. <br/> The user will receive an email notification.',
            'User <b>{userName}</b> profile will lose moderator rights. <br/>  The user will receive an email notification.',
        ],
        submitText: [
            'Entrust',
            'Recall',
        ],
        successMessage: [
            'The user was granted moderator rights',
            'Moderator rights have been revoked',
        ],
        methods: ['entrustModeratorRole', 'recallModeratorRole'].map(function (action) { return function (id) { return updateStatus(id, action); }; }),
    },
    isVerified: {
        label: ['Verify user', 'Unverify user'],
        confirmMessage: [
            'User <b>{userName}</b> profile will have a verification sign. <br/> The user will receive an email notification.',
            'The user <b>{userName}</b> profile will lose the verification sign. <br/> The user will receive an email notification.',
        ],
        submitText: [
            'Verify',
            'Unverify',
        ],
        successMessage: [
            'User verified',
            'User unverified',
        ],
        methods: ['verify', 'unverify'].map(function (action) { return function (id) { return updateStatus(id, action); }; }),
    },
    isEnabled: {
        label: ['Enable user', 'Disable user'],
        confirmMessage: [
            'User <b>{userName}</b> will be enabled.',
            'User <b>{userName}</b> will be disabled.',
        ],
        submitText: [
            'Enable',
            'Disable',
        ],
        successMessage: [
            'User enabled',
            'User disabled',
        ],
        methods: ['enable', 'disable'].map(function (action) { return function (id) { return updateStatus(id, action); }; }),
    },
    isActive: {
        label: ['Restore user', 'Deactivate user'],
        confirmMessage: [
            'User <b>{userName}</b> will be restored.',
            'User <b>{userName}</b> will be deactivated.',
        ],
        submitText: [
            'Restore',
            'Deactivate',
        ],
        successMessage: [
            'User restored',
            'User deactivated',
        ],
        methods: ['activate', 'deactivate'].map(function (action) { return function (id) { return updateStatus(id, action); }; }),
    },
};
export default mixin.extend({
    filters: {
        relativeDate: relativeDate,
    },
    components: {
        PasswordChange: PasswordChange,
        VFormLineLink: VFormLineLink,
        VTags: VTags,
    },
    data: function () {
        return {
            fields: {
                name: '',
                surname: '',
                login: '',
                birthDate: { date: '', month: '', year: '' },
                description: '',
                isPlatformModerator: false,
                isVerified: false,
                isEnabled: false,
                isActive: false,
                createDate: '',
                updateDate: '',
            },
            fetchedName: '',
            fetchedLogin: '',
            constraints: constraints,
            monthNames: MONTH_NAMES,
        };
    },
    created: function () {
        this.fetchData();
    },
    computed: {
        id: function () {
            return this.$route.params.id;
        },
        statusLabels: function () {
            return {
                isPlatformModerator: FLAGS.isPlatformModerator.label[Number(this.fields.isPlatformModerator)],
                isVerified: FLAGS.isVerified.label[Number(this.fields.isVerified)],
                isEnabled: FLAGS.isEnabled.label[Number(this.fields.isEnabled)],
                isActive: FLAGS.isActive.label[Number(this.fields.isActive)],
            };
        },
        passwordHandler: function () {
            var _this = this;
            return function (password) { return updatePassword(_this.id, { password: password })
                .then(function () {
                _this.$notify.success('Password changed');
                _this.fetchData(['updateDate']);
            }); };
        },
        tags: function () {
            var array = [];
            if (this.fields.isPlatformModerator) {
                array.push({
                    icon: 'moderator-on',
                    label: 'moderator',
                });
            }
            if (this.fields.isVerified) {
                array.push({
                    icon: 'verified',
                    label: 'verified',
                });
            }
            if (!this.fields.isEnabled) {
                array.push({
                    icon: 'disabled',
                    label: 'disabled',
                });
            }
            if (!this.fields.isActive) {
                array.push({
                    icon: 'deactivate',
                    label: 'deactivated',
                });
            }
            return array;
        },
    },
    methods: {
        fetchData: function (fieldsToUpdate) {
            var _this = this;
            if (!this.id) {
                return;
            }
            getSingle(this.id)
                .then(function (fields) {
                _this.fetchedName = formatUserName(fields);
                _this.fetchedLogin = fields.login;
                if (!fieldsToUpdate) {
                    _this.fields = __assign(__assign({}, fields), { birthDate: mapBirthDate.income(fields.birthDate) });
                    return;
                }
                fieldsToUpdate.forEach(function (fieldName) {
                    var value = (fieldName === 'birthDate'
                        ? mapBirthDate.income(fields[fieldName])
                        : fields[fieldName]);
                    _this.$set(_this.fields, fieldName, value);
                });
            })
                .catch(function (error) {
                _this.handleError(error);
                _this.close();
            });
        },
        changeStatus: function (fieldName) {
            var _this = this;
            var confirmType = Number(this.fields[fieldName]);
            var confirmMessage = FLAGS[fieldName].confirmMessage[confirmType];
            this.$confirm({
                type: confirmType ? 'negative' : 'primary',
                header: FLAGS[fieldName].label[confirmType],
                message: confirmMessage.replace('{userName}', this.fetchedLogin),
                submitText: FLAGS[fieldName].submitText[confirmType],
            })
                .then(function () { return FLAGS[fieldName].methods[confirmType](_this.id); })
                .then(function () {
                _this.$notify.success(FLAGS[fieldName].successMessage[confirmType]);
                _this.fetchData(['updateDate', fieldName]);
            })
                .catch((function (err) { return err && _this.$handleError(err); }));
        },
        onSubmit: function () {
            var _this = this;
            if (this.isFormInvalid()) {
                return;
            }
            var body = __assign(__assign({}, this.fields), { birthDate: mapBirthDate.outcome(this.fields.birthDate) });
            edit(this.id, body)
                .then(function () {
                _this.$notify.success('Changes saved');
                _this.close();
            })
                .catch(function (error) { return _this.handleError(error); });
        },
        close: function () {
            this.$router.push({ name: 'users' });
        },
    },
});
