var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return Object.keys(_vm.fields).length
    ? _c(
        "lyra-dialog",
        {
          staticClass: "lyra-dialog--ungap",
          on: { close: _vm.onClose },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function() {
                  return [
                    _c("h2", { staticClass: "lyra-dialog__title" }, [
                      _vm._v("Verification request")
                    ]),
                    _c(
                      "lyra-tooltip",
                      { attrs: { "append-to-body": "" } },
                      [
                        _c(
                          "lyra-button",
                          {
                            staticClass: "lyra-dialog__close",
                            attrs: {
                              type: "button",
                              theme: "neutral",
                              tone: "light",
                              shape: "circle",
                              size: "micro"
                            },
                            on: { click: _vm.onClose }
                          },
                          [_c("v-icon", { attrs: { name: "close" } })],
                          1
                        ),
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [_vm._v("Close")]
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "footer",
                fn: function() {
                  return [
                    _c("div", { staticClass: "form__additional" }, [
                      _c("div", [
                        _vm._v(
                          "Created " +
                            _vm._s(
                              _vm._f("relativeDate")(_vm.fields.createDate)
                            )
                        )
                      ]),
                      _c("div", [
                        _vm._v(
                          "Modified " +
                            _vm._s(
                              _vm._f("relativeDate")(_vm.fields.updateDate)
                            )
                        )
                      ])
                    ]),
                    _vm.fields.state === "pending"
                      ? _c(
                          "div",
                          { staticClass: "form__controls" },
                          [
                            _c(
                              "lyra-button",
                              {
                                attrs: { theme: "negative", shape: "rect" },
                                on: {
                                  click: function($event) {
                                    return _vm.process("reject")
                                  }
                                }
                              },
                              [_vm._v("Refuse")]
                            ),
                            _c(
                              "lyra-button",
                              {
                                attrs: { theme: "positive", shape: "rect" },
                                on: {
                                  click: function($event) {
                                    return _vm.process("accept")
                                  }
                                }
                              },
                              [_vm._v("Approve")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            2007211491
          )
        },
        [
          [
            _c("dl", { staticClass: "form__list form__list--single" }, [
              _c("dt", { staticClass: "form__list-label" }, [_vm._v("Name")]),
              _c(
                "dd",
                { staticClass: "form__list-item" },
                [_c("v-user", { attrs: { user: _vm.fields.user } })],
                1
              )
            ]),
            _c("hr"),
            _c(
              "dl",
              { staticClass: "form__list" },
              [
                _c("v-form-line-link", { attrs: { id: _vm.fields.user.id } }),
                _c("v-form-line-link", {
                  attrs: { login: _vm.fields.user.login }
                }),
                _c("v-form-line-link", {
                  attrs: { "phone-number": _vm.fields.user.phoneNumber }
                })
              ],
              1
            ),
            _c("hr"),
            _c("dl", { staticClass: "form__list" }, [
              _c("dt", { staticClass: "form__list-label" }, [
                _vm._v("Attached files")
              ]),
              _c(
                "dd",
                { staticClass: "form__list-item" },
                _vm._l(_vm.fields.attachments, function(attachment) {
                  return _c(
                    "a",
                    {
                      key: attachment.id,
                      staticClass: "form__attachment",
                      attrs: { href: attachment.link, target: "_blank" }
                    },
                    [
                      _c("v-file-type", {
                        attrs: {
                          name: attachment.name,
                          type: "neutral-dark",
                          float: "before"
                        }
                      }),
                      _vm._v(_vm._s(attachment.name))
                    ],
                    1
                  )
                }),
                0
              )
            ]),
            _c("hr"),
            _c("dl", { staticClass: "form__list" }, [
              _c("dt", { staticClass: "form__list-label" }, [_vm._v("Status")]),
              _c(
                "dd",
                { staticClass: "form__list-item" },
                [_c("v-request-state", { attrs: { state: _vm.fields.state } })],
                1
              )
            ])
          ]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }