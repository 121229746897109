import { __assign } from "tslib";
import relativeDate from 'filters/relativeDate';
import handleFieldsErrorMixin from 'mixins/handleFieldsError';
import { getRequestById, processRequest, } from 'shared/provider/api/hospitals';
import VUser from 'components/VUser/component.vue';
import VFormLineLink from 'components/VFormLineLink/component.vue';
import VRequestState from 'components/VRequestState/component.vue';
import { countriesMapper } from 'store/modules/countries';
export default handleFieldsErrorMixin.extend({
    components: {
        VUser: VUser,
        VFormLineLink: VFormLineLink,
        VRequestState: VRequestState,
    },
    created: function () {
        this.fetchData();
    },
    computed: __assign(__assign({}, countriesMapper.mapState(['countries'])), { id: function () {
            return this.$route.params.id;
        },
        name: function () {
            return this.fields.name;
        },
        countryName: function () {
            var _this = this;
            var _a;
            return ((_a = this.countries.find(function (c) { return c.id === _this.fields.countryId; })) === null || _a === void 0 ? void 0 : _a.name) || '';
        } }),
    data: function () {
        return {
            isFetching: false,
            fields: {
                userAccount: {},
            },
            closeRouteName: 'hospitals.requests',
        };
    },
    filters: {
        relativeDate: relativeDate,
    },
    methods: {
        getRequestByIdProvider: getRequestById,
        processRequestProvider: processRequest,
        fetchData: function () {
            var _this = this;
            this.getRequestByIdProvider(this.id)
                .then(function (response) {
                _this.fields = response;
            })
                .catch(function (error) {
                _this.$handleError(error);
                _this.onClose();
            });
        },
        process: function (action) {
            var _this = this;
            this.processRequestProvider({ action: action, id: this.id })
                .then(function () { _this.$notify.success('Changes saved'); })
                .catch(function (error) { return _this.$handleError(error); })
                .finally(function () { return _this.onClose(); });
        },
        onClose: function () {
            this.$router.push({ name: this.closeRouteName });
        },
    },
});
