var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app" },
    [
      _c("lyra-notify"),
      _c("the-header", { staticClass: "app__header" }),
      _c("div", { staticClass: "app__body" }, [
        _c(
          "div",
          { staticClass: "app__container" },
          [
            _c("the-sidebar", { staticClass: "app__sidebar" }),
            _c("main", { staticClass: "app__main" }, [_c("router-view")], 1)
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }