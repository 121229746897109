import relativeDate from 'filters/relativeDate';
import PasswordChange from 'components/VPasswordChange/component.vue';
import VTags from 'components/VTags/component.vue';
import handleFieldsErrorMixin from 'mixins/handleFieldsError';
import constraints from 'shared/constraints/administrator';
import parseJwt from '@lyra_npm/lyra.ui.components/utils/parseJwt';
import tokenManager from 'shared/utils/tokenManager';
import { getSingle, setPassword, updateStatus, update, } from 'shared/provider/api/admins';
var ENABLE_ACTIONS = ['Enable', 'Disable'];
var ACTIVATION_ACTIONS = ['Activate', 'Deactivate'];
export default handleFieldsErrorMixin.extend({
    components: {
        PasswordChange: PasswordChange,
        VTags: VTags,
    },
    filters: {
        relativeDate: relativeDate,
    },
    data: function () {
        return {
            headerName: '',
            fields: {
                id: '',
                login: '',
                description: '',
                isActive: true,
                isEnabled: true,
                createDate: '',
                updateDate: '',
            },
            isCurrentUser: true,
            constraints: constraints,
        };
    },
    computed: {
        passwordHandler: function () {
            var _this = this;
            return function (password) { return setPassword(_this.id, password)
                .then(function () {
                _this.$notify.success('Password changed');
                _this.fetchData(['updateDate']);
            }); };
        },
        id: function () {
            return this.$route.params.id;
        },
        activationNext: function () {
            return ACTIVATION_ACTIONS[Number(this.fields.isActive)];
        },
        enableNext: function () {
            return ENABLE_ACTIONS[Number(this.fields.isEnabled)];
        },
        tags: function () {
            var array = [];
            if (!this.fields.isEnabled) {
                array.push({
                    icon: 'disabled',
                    label: 'disabled',
                });
            }
            if (!this.fields.isActive) {
                array.push({
                    icon: 'deactivate',
                    label: 'deactivated',
                });
            }
            return array;
        },
    },
    created: function () {
        var _a;
        this.isCurrentUser = ((_a = parseJwt(tokenManager.get())) === null || _a === void 0 ? void 0 : _a.sub) === this.id;
        this.fetchData();
    },
    methods: {
        fetchData: function (updateFields) {
            var _this = this;
            getSingle(this.id)
                .then(function (data) {
                if (updateFields) {
                    updateFields.forEach(function (field) {
                        _this.$set(_this.fields, field, data[field]);
                    });
                }
                else {
                    _this.$set(_this, 'fields', data);
                }
                _this.headerName = data.login;
            })
                .catch(function (error) {
                _this.$handleError(error);
                _this.onClose();
            });
        },
        onClose: function () {
            this.$router.push({ name: 'administrators' });
        },
        onSubmit: function () {
            var _this = this;
            if (this.isFormInvalid()) {
                return;
            }
            var payload = {
                login: this.fields.login,
                description: this.fields.description,
            };
            update(this.id, payload)
                .then(function () {
                _this.$notify.success('Changes saved');
                _this.onClose();
            })
                .catch(function (e) { return _this.handleError(e); });
        },
        enableToggle: function () {
            this.updateStatus(this.enableNext, this.fields.isEnabled ? 'negative' : 'primary');
        },
        activationToggle: function () {
            this.updateStatus(this.activationNext, this.fields.isActive ? 'negative' : 'primary');
        },
        updateStatus: function (action, confirmType) {
            var _this = this;
            var actionLowerCased = action.toLowerCase();
            this.$confirm({
                header: action + " administrator?",
                message: "Administrator " + this.headerName + " will be " + actionLowerCased + "d",
                submitText: action,
                type: confirmType,
            })
                .then(function () { return updateStatus(_this.id, actionLowerCased); })
                .then(function () {
                _this.$notify.success("Administrator " + actionLowerCased + "d");
                return _this.fetchData(['isActive', 'isEnabled', 'updateDate']);
            })
                .catch(function (err) { return err && _this.$handleError(err); });
        },
    },
});
