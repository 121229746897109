var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.tags.length
    ? _c(
        "div",
        { staticClass: "tags" },
        _vm._l(_vm.tags, function(ref) {
          var icon = ref.icon
          var label = ref.label
          return _c(
            "div",
            { staticClass: "tags__item" },
            [
              _c("v-icon", {
                attrs: { name: icon, type: "neutral", float: "included" }
              }),
              _vm._v(_vm._s(label))
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }