import { __spreadArrays } from "tslib";
import shuffle from 'lodash/shuffle';
var DICTIONARY = {
    LOWERCASE: 'abcdefghijklmnopqrstuvwxyz_-@#$%^&*0123456789',
    UPPERCASE: 'abcdefghijklmnopqrstuvwxyz'.toUpperCase(),
};
var LENGTH = {
    MIN: 8,
    MAX: 16,
};
// TODO
// move it to Lyra.UI
var randomInt = function (min, max) { return (Math.floor(Math.random() * (max - min + 1) + min)); };
var randomIntArray = function (length, min, max) {
    var array = [];
    while (array.length < length && max - min > length) {
        var int = randomInt(min, max);
        if (!array.includes(int)) {
            array.push(int);
        }
    }
    return array;
};
var randomCharArray = function (_a) {
    var length = _a.length, dictionary = _a.dictionary;
    return (randomIntArray(length, 0, dictionary.length - 1).map(function (index) { return dictionary[index]; }));
};
export default (function () {
    var lowerLength = randomInt(LENGTH.MIN, LENGTH.MAX - 1);
    var upperLength = LENGTH.MAX - lowerLength;
    var lowerArray = randomCharArray({ length: lowerLength, dictionary: DICTIONARY.LOWERCASE });
    var upperArray = randomCharArray({ length: upperLength, dictionary: DICTIONARY.UPPERCASE });
    return shuffle(__spreadArrays(lowerArray, upperArray)).join('');
});
