var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app__wrapper" },
    [
      _c("div", { staticClass: "app__title-wrapper" }, [
        _c("h1", { staticClass: "app__title" }, [_vm._v(_vm._s(_vm.title))])
      ]),
      _c("v-tabs", { attrs: { tabs: _vm.tabs } }),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }