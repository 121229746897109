var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout--grow" },
    [
      _c(
        "portal",
        { attrs: { to: "search" } },
        [
          _c(
            "v-search",
            _vm._b(
              {
                attrs: { value: _vm.params.filter },
                on: { input: _vm.setFilter }
              },
              "v-search",
              _vm.filterConstraints,
              false
            )
          )
        ],
        1
      ),
      _c("lyra-list", {
        attrs: {
          items: _vm.users.items,
          columns: _vm.columns,
          isFetching: _vm.isFetching,
          rowLink: _vm.openEdit,
          sortedBy: _vm.params.sortBy,
          sortDirection: _vm.params.sortOrder === "desc" ? 1 : -1,
          emptyMessage: _vm.params.filter ? "No user found" : "No data"
        },
        on: { "sort:change": _vm.onSortChange },
        scopedSlots: _vm._u([
          {
            key: "layout.fetching",
            fn: function() {
              return [_c("lyra-spinner")]
            },
            proxy: true
          },
          {
            key: "name",
            fn: function(ref) {
              var row = ref.row
              return [_vm._v(_vm._s(_vm.formatUserName(row)))]
            }
          },
          {
            key: "isPlatformModerator",
            fn: function(ref) {
              var item = ref.item
              return [
                item
                  ? _c("v-icon", {
                      attrs: { name: "moderator-on", type: "neutral" }
                    })
                  : _vm._e()
              ]
            }
          },
          {
            key: "isVerified",
            fn: function(ref) {
              var item = ref.item
              return [
                item
                  ? _c("v-icon", {
                      attrs: { name: "verified", type: "neutral" }
                    })
                  : _vm._e()
              ]
            }
          },
          {
            key: "isEnabled",
            fn: function(ref) {
              var item = ref.item
              return [
                !item
                  ? _c("v-icon", {
                      attrs: { name: "disabled", type: "neutral" }
                    })
                  : _vm._e()
              ]
            }
          },
          {
            key: "isActive",
            fn: function(ref) {
              var item = ref.item
              return [
                !item
                  ? _c("v-icon", {
                      attrs: { name: "deactivate", type: "neutral" }
                    })
                  : _vm._e()
              ]
            }
          },
          {
            key: "createDate",
            fn: function(ref) {
              var item = ref.item
              return [[_vm._v(_vm._s(_vm._f("formatDate")(item)))]]
            }
          },
          {
            key: "updateDate",
            fn: function(ref) {
              var item = ref.item
              return [[_vm._v(_vm._s(_vm._f("formatDate")(item)))]]
            }
          }
        ])
      }),
      _vm.pagination
        ? _c(
            "lyra-pagination",
            _vm._b(
              {
                attrs: { "inactive-button-params": { tone: "light" } },
                on: { changePage: _vm.setPageNumber }
              },
              "lyra-pagination",
              _vm.pagination,
              false
            ),
            [
              _c("v-icon", {
                attrs: { slot: "prev", name: "arrow-left", type: "small-60" },
                slot: "prev"
              }),
              _c("v-icon", {
                attrs: { slot: "next", name: "arrow-right", type: "small-60" },
                slot: "next"
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "transition",
        { attrs: { name: "dialog-transition" } },
        [_c("router-view")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }