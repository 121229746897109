import VBase from 'index/pages/hospitals/index';
export default VBase.extend({
    data: function () {
        return {
            title: 'Business profiles',
            tabs: [
                { label: 'Registered', path: '/business-profiles/registered' },
                { label: 'Requests', path: '/business-profiles/requests' },
            ],
        };
    },
});
