var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.linkHref
    ? _c("div", { staticClass: "form__list-line" }, [
        _c("dt", { staticClass: "form__list-label" }, [
          _vm._v(_vm._s(_vm.label))
        ]),
        _c(
          "dd",
          { staticClass: "form__list-item" },
          [
            _c(
              "a",
              {
                staticClass: "form__link",
                attrs: { href: _vm.linkHref, target: "_blank" }
              },
              [_vm._v(_vm._s(_vm.linkDisplayed))]
            ),
            _vm.copy
              ? _c(
                  "v-copy",
                  _vm._b(
                    { staticClass: "form__copy" },
                    "v-copy",
                    _vm.copy,
                    false
                  )
                )
              : _vm._e()
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }