import { getHttp } from 'shared/utils/http';
export var getList = function () { return getHttp().get({
    path: '/admin-api/api/admins',
}); };
export var getSingle = function (id) { return getHttp().get({
    path: "/admin-api/api/admins/" + id,
}); };
export var create = function (body) { return getHttp().post({
    path: '/admin-api/api/admins/',
    body: body,
}); };
export var update = function (id, body) { return getHttp().put({
    path: "/admin-api/api/admins/" + id,
    body: body,
}); };
export var updateStatus = function (id, action) { return getHttp().patch({
    path: "/admin-api/api/admins/" + id + "/" + action,
    body: {},
}); };
export var setPassword = function (id, password) { return getHttp().patch({
    path: "/admin-api/api/admins/" + id + "/setPassword",
    body: { password: password },
}); };
