import VBase from 'index/pages/hospitals/registered/index';
import { getRegisteredList } from 'shared/provider/api/business-profiles';
export default VBase.extend({
    data: function () {
        return {
            childRoute: 'business-profile.registered',
            selfRoute: 'business-profiles.registered',
        };
    },
    methods: {
        fetchData: function () {
            var _this = this;
            this.isFetching = true;
            getRegisteredList(this.params)
                .then(function (response) {
                _this.requests.items = response.items;
                _this.requests.pageCount = response.pageCount;
            })
                .catch(this.$handleError)
                .finally(function () {
                _this.isFetching = false;
            });
        },
    },
});
