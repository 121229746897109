import Vue from 'vue';
import VAvatar from 'index/components/VAvatar/component';
import formatUserName from 'index/utils/formatUserName';
export default Vue.extend({
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    components: {
        VAvatar: VAvatar,
    },
    computed: {
        nameFormatted: function () {
            return formatUserName(this.user);
        },
    },
});
