import VBase from 'index/pages/hospitals/registered/_id';
import { getRegisteredById, toggleIsActive } from 'shared/provider/api/business-profiles';
export default VBase.extend({
    data: function () {
        return {
            registerType: 'Business profile',
            closeRouteName: 'business-profiles.registered',
        };
    },
    methods: {
        toggleIsActiveProvider: toggleIsActive,
        getRegisteredByIdProvider: getRegisteredById,
    },
});
