import Vue from 'vue';
import VTabs from 'components/VNavigationTabs/component.vue';
export default Vue.extend({
    components: {
        VTabs: VTabs,
    },
    data: function () {
        return {
            tabs: [
                { label: 'All users', path: '/users/all' },
                { label: 'Verification requests', path: '/users/requests' },
            ],
        };
    },
});
