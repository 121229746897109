import { __assign } from "tslib";
import generatePassword from 'shared/utils/generatePassword';
import constraints from 'shared/constraints/user';
import mixin from 'mixins/handleFieldsError';
import { create } from 'shared/provider/api/users';
import mapBirthDate from 'shared/provider/mapper/birthDate';
var MONTH_NAMES = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
];
export default mixin.extend({
    data: function () {
        return {
            fields: {
                name: '',
                surname: '',
                login: '',
                birthDate: { date: '', month: '', year: '' },
                description: '',
                password: '',
                isEnabled: false,
            },
            constraints: constraints,
            monthNames: MONTH_NAMES,
        };
    },
    methods: {
        generatePassword: generatePassword,
        close: function () {
            this.$router.push({ name: 'users' });
        },
        onSubmit: function () {
            var _this = this;
            if (this.isFormInvalid()) {
                return;
            }
            var body = __assign(__assign({}, this.fields), { birthDate: mapBirthDate.outcome(this.fields.birthDate) });
            create(body)
                .then(function () {
                _this.$notify.success('User created');
                _this.close();
            })
                .catch(function (error) {
                _this.handleError(error);
            });
        },
    },
});
