import Vue from 'vue';
import vuex from 'vuex';
import { createStore, Module } from 'vuex-smart-module';
import countries from './modules/countries';
Vue.use(vuex);
export default createStore(new Module({
    modules: {
        countries: countries,
    },
}));
