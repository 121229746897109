import Vue from 'vue';
import { isNotEmptyString, isString } from '@lyra_npm/lyra.ui.components/utils/validators';
import generateColorFactory from '@lyra_npm/lyra.ui.components/utils/color';
var generateColor = generateColorFactory();
export default Vue.extend({
    inheritAttrs: false,
    props: {
        name: {
            type: String,
            default: '',
        },
        avatar: {
            type: String,
            default: '',
        },
        surname: {
            type: String,
            default: '',
        },
        id: {
            type: String,
            default: '',
        },
    },
    computed: {
        initials: function () {
            if (!isNotEmptyString(this.name)) {
                return '';
            }
            if (isNotEmptyString(this.name) && isNotEmptyString(this.surname)) {
                return this.surname.charAt(0) + this.name.charAt(0);
            }
            return this.name.split(/\s+/).map(function (str) { return str.charAt(0); }).join('').toUpperCase();
        },
        style: function () {
            if (this.avatar) {
                return {
                    backgroundImage: "url(" + this.avatar + ")",
                };
            }
            return {
                backgroundColor: generateColor(isString(this.id) ? this.id : ''),
            };
        },
    },
});
