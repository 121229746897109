import dayjs from 'dayjs';
import dayjsRelative from 'dayjs/plugin/relativeTime';
dayjs.extend(dayjsRelative);
export default (function (date) {
    if (!date) {
        return '';
    }
    var parsedDate = dayjs(date);
    if (!parsedDate.isValid()) {
        return 'Invalid Date';
    }
    if (dayjs().diff(parsedDate, 'hour') > 24) {
        return parsedDate.format('DD.MM.YYYY HH:mm');
    }
    return parsedDate.fromNow();
});
