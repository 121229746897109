var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "lyra-input",
    _vm._b(
      {
        staticClass: "search",
        attrs: {
          value: _vm.value,
          type: "search",
          placeholder: "Search",
          autocomplete: "off"
        },
        on: { input: _vm.onInput }
      },
      "lyra-input",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "template",
        { slot: "inner-before" },
        [_c("v-icon", { attrs: { name: "search", type: "neutral" } })],
        1
      ),
      _vm.value
        ? _c(
            "lyra-button",
            {
              attrs: {
                slot: "inner-after",
                theme: "neutral",
                tone: "light",
                shape: "circle",
                size: "micro"
              },
              on: {
                click: function($event) {
                  return _vm.onInput("")
                }
              },
              slot: "inner-after"
            },
            [_c("v-icon", { attrs: { name: "cross" } })],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }