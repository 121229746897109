import Vue from 'vue';
export default Vue.extend({
    props: {
        name: {
            type: String,
            default: 'Counter name',
        },
        count: {
            type: Number,
            default: 0,
        },
    },
});
