var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "lyra-dialog",
    {
      on: { close: _vm.onClose },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c("h2", { staticClass: "lyra-dialog__title" }, [
                _vm._v(_vm._s(_vm.headerName))
              ]),
              _c("v-tags", { attrs: { tags: _vm.tags } }),
              _c(
                "lyra-tooltip",
                { attrs: { "append-to-body": "" } },
                [
                  _c(
                    "lyra-button",
                    {
                      staticClass: "lyra-dialog__close",
                      attrs: {
                        type: "button",
                        theme: "neutral",
                        tone: "light",
                        shape: "circle",
                        size: "micro"
                      },
                      on: { click: _vm.onClose }
                    },
                    [_c("v-icon", { attrs: { name: "close" } })],
                    1
                  ),
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _vm._v("Close")
                  ])
                ],
                1
              )
            ]
          },
          proxy: true
        },
        {
          key: "footer",
          fn: function() {
            return [
              _c("div", { staticClass: "form__additional" }, [
                _c("div", [
                  _vm._v(
                    "Created " +
                      _vm._s(_vm._f("relativeDate")(_vm.fields.createDate))
                  )
                ]),
                _c("div", [
                  _vm._v(
                    "Modified " +
                      _vm._s(_vm._f("relativeDate")(_vm.fields.updateDate))
                  )
                ])
              ]),
              _c(
                "div",
                { staticClass: "form__controls" },
                [
                  _c(
                    "lyra-tooltip",
                    {
                      staticClass: "lyra-tooltip--gapped",
                      scopedSlots: _vm._u([
                        {
                          key: "content",
                          fn: function() {
                            return [_vm._v(_vm._s(_vm.enableNext))]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c(
                        "lyra-button",
                        {
                          attrs: {
                            theme: "neutral",
                            tone: "light",
                            shape: "circle",
                            size: "small",
                            disabled: _vm.isCurrentUser
                          },
                          on: { click: _vm.enableToggle }
                        },
                        [
                          _c("v-icon", {
                            attrs: {
                              name: _vm.fields.isEnabled
                                ? "disabled"
                                : "enabled"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "lyra-tooltip",
                    {
                      staticClass: "lyra-tooltip--gapped",
                      scopedSlots: _vm._u([
                        {
                          key: "content",
                          fn: function() {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm.fields.isActive ? "Deactivate" : "Restore"
                                )
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c(
                        "lyra-button",
                        {
                          attrs: {
                            theme: "neutral",
                            tone: "light",
                            shape: "circle",
                            size: "small",
                            disabled: _vm.isCurrentUser
                          },
                          on: { click: _vm.activationToggle }
                        },
                        [
                          _c("v-icon", {
                            attrs: {
                              name: _vm.fields.isActive
                                ? "deactivate"
                                : "restore"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "form__separator" }),
                  _c(
                    "lyra-button",
                    {
                      attrs: {
                        theme: "primary",
                        shape: "rect",
                        form: "edit-dialog",
                        type: "submit"
                      }
                    },
                    [_vm._v("Save")]
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      [
        _c(
          "form",
          {
            staticClass: "form",
            attrs: { id: "edit-dialog", novalidate: "" },
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.onSubmit($event)
              }
            }
          },
          [
            _c(
              "lyra-input",
              _vm._b(
                {
                  ref: "fieldLogin",
                  staticClass: "form__element lyra-base-input--required",
                  model: {
                    value: _vm.fields.login,
                    callback: function($$v) {
                      _vm.$set(_vm.fields, "login", $$v)
                    },
                    expression: "fields.login"
                  }
                },
                "lyra-input",
                _vm.constraints.login,
                false
              ),
              [_vm._v("Login")]
            ),
            _c(
              "lyra-input",
              _vm._b(
                {
                  ref: "fieldDescription",
                  staticClass: "form__element",
                  model: {
                    value: _vm.fields.description,
                    callback: function($$v) {
                      _vm.$set(_vm.fields, "description", $$v)
                    },
                    expression: "fields.description"
                  }
                },
                "lyra-input",
                _vm.constraints.description,
                false
              ),
              [_vm._v("Description")]
            ),
            _c("password-change", {
              staticClass: "form__element",
              attrs: {
                constraints: _vm.constraints.password,
                handler: _vm.passwordHandler
              }
            })
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }