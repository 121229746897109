import Vue from 'vue';
export default Vue.extend({
    props: {
        tags: {
            type: Array,
            default: function () {
                return [];
            },
        },
    },
});
