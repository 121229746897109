import { __spreadArrays } from "tslib";
import Vue from 'vue';
import { capitalize } from '@lyra_npm/lyra.ui.components/utils/string.js';
import { isArray } from '@lyra_npm/lyra.ui.components/utils/validators.js';
var INPUT_PREFIX = 'field';
export default Vue.extend({
    methods: {
        handleError: function (err) {
            var _this = this;
            return Vue.prototype.$handleError(err, function (fields) { return _this.handleFieldsError(fields); });
        },
        handleFieldsError: function (fields) {
            var _this = this;
            Object.keys(fields).forEach(function (key) {
                var refName = "" + INPUT_PREFIX + capitalize(key);
                var ref = _this.$refs[refName];
                var message = fields[key];
                if (ref) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                    // @ts-ignore
                    ref.notify(message);
                }
                else {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                    // @ts-ignore
                    _this.$notify.error(message);
                }
            });
            var input = this.getInvalidInput();
            if (input) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                input.focusOn();
            }
        },
        isFormInvalid: function () {
            var inputs = this.getInputs();
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            inputs.forEach(function (input) { input.validate(); });
            var input = this.getInvalidInput();
            if (input) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                input.focusOn();
            }
            return !!input;
        },
        getInputs: function () {
            var refs = this.$refs;
            return Object.keys(refs)
                .filter(function (key) { return key.startsWith(INPUT_PREFIX); })
                .map(function (key) { return refs[key]; })
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                .reduce(function (acc, item) { return (isArray(item) ? __spreadArrays(acc, item) : __spreadArrays(acc, [item])); }, [])
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                .filter(Boolean);
        },
        getInvalidInput: function () {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            return this.getInputs().find(function (i) { return !i.valid; });
        },
    },
});
