import { __assign } from "tslib";
import Vue from 'vue';
import debounce from 'lodash/debounce';
import { LyraList, LyraPagination } from '@lyra_npm/lyra.ui.components';
import { getRequestsList } from 'shared/provider/api/hospitals';
import formatDate from 'filters/formatDate';
import VRequestState from 'components/VRequestState/component.vue';
var DEFAULT_PARAMS = {
    limit: 20,
    pageNumber: 0,
    sortBy: 'createDate',
    sortOrder: 'desc',
};
export default Vue.extend({
    components: {
        LyraList: LyraList,
        LyraPagination: LyraPagination,
        VRequestState: VRequestState,
    },
    filters: {
        formatDate: formatDate('DD.MM.YYYY'),
    },
    data: function () {
        return {
            requests: {
                items: [],
                pageCount: 0,
            },
            isFetching: false,
            params: __assign({}, DEFAULT_PARAMS),
            columns: [
                { field: 'name', label: 'Name' },
                { field: 'city', label: 'City' },
                { field: 'userAccount.login', label: 'Requester' },
                { field: 'createDate', label: 'Created', sortable: true },
                { field: 'state', label: 'Status' },
            ],
            childRoute: 'hospital.request',
            selfRoute: 'hospitals.requests',
        };
    },
    computed: {
        pagination: function () {
            var pageCount = this.requests.pageCount;
            if (!pageCount || pageCount <= 1) {
                return null;
            }
            return {
                total: pageCount,
                current: this.params.pageNumber + 1,
            };
        },
    },
    watch: {
        params: {
            deep: true,
            handler: debounce(function () {
                this.fetchData();
            }, 300),
        },
        $route: function () {
            if (this.$route.name === this.selfRoute) {
                this.fetchData();
            }
        },
    },
    mounted: function () {
        this.fetchData();
    },
    methods: {
        getRequestsList: getRequestsList,
        fetchData: function () {
            var _this = this;
            this.isFetching = true;
            this.getRequestsList(this.params)
                .then(function (response) {
                // TODO: change response.data to response.items, when MVSCP1-2259 will be done
                _this.requests.items = response.data;
                _this.requests.pageCount = response.pageCount;
            })
                .catch(this.$handleError)
                .finally(function () {
                _this.isFetching = false;
            });
        },
        setPageNumber: function (pageNumber) {
            this.params.pageNumber = pageNumber - 1;
        },
        onSortChange: function (_a) {
            var direction = _a.direction, field = _a.field;
            this.params = __assign(__assign({}, DEFAULT_PARAMS), { sortBy: field, sortOrder: direction === 1 ? 'desc' : 'asc' });
        },
        openEdit: function (_a) {
            var id = _a.id;
            return { name: this.childRoute, params: { id: id } };
        },
    },
});
