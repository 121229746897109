var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "counter" }, [
    _c("div", { staticClass: "counter__name" }, [_vm._v(_vm._s(_vm.name))]),
    _c("div", { staticClass: "counter__count" }, [_vm._v(_vm._s(_vm.count))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }