var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app__wrapper" },
    [
      _c(
        "div",
        { staticClass: "app__title-wrapper" },
        [
          _c("h1", { staticClass: "app__title" }, [
            _vm._v(_vm._s(_vm.texts.pageTitle))
          ]),
          _vm.list.items.length
            ? _c(
                "lyra-button",
                {
                  attrs: { theme: "primary", shape: "rect", size: "small" },
                  on: { click: _vm.deleteAll }
                },
                [
                  _c("v-icon", {
                    attrs: { name: "deactivate", float: "included" }
                  }),
                  _vm._v("Delete all")
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("lyra-list", {
        attrs: {
          columns: _vm.columns,
          isFetching: _vm.isFetching,
          items: _vm.list.items,
          emptyMessage: "No data",
          "hide-hover": ""
        },
        scopedSlots: _vm._u([
          {
            key: "layout.fetching",
            fn: function() {
              return [_c("lyra-spinner")]
            },
            proxy: true
          },
          {
            key: "owner",
            fn: function(ref) {
              var item = ref.item
              return [_vm._v(_vm._s(_vm.formatUserName(item)))]
            }
          },
          {
            key: "publishDate",
            fn: function(ref) {
              var item = ref.item
              return [_vm._v(_vm._s(_vm._f("formatDate")(item)))]
            }
          },
          {
            key: "delete",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "lyra-button",
                  {
                    attrs: {
                      theme: "neutral",
                      tone: "light",
                      shape: "circle",
                      size: "small"
                    },
                    on: {
                      click: function($event) {
                        return _vm.deleteItem(row)
                      }
                    }
                  },
                  [_c("v-icon", { attrs: { name: "deactivate" } })],
                  1
                )
              ]
            }
          }
        ])
      }),
      _vm.pagination
        ? _c(
            "lyra-pagination",
            _vm._b(
              {
                attrs: { "inactive-button-params": { tone: "light" } },
                on: { changePage: _vm.changePage }
              },
              "lyra-pagination",
              _vm.pagination,
              false
            ),
            [
              _c("v-icon", {
                attrs: { slot: "prev", name: "arrow-left", type: "small-60" },
                slot: "prev"
              }),
              _c("v-icon", {
                attrs: { slot: "next", name: "arrow-right", type: "small-60" },
                slot: "next"
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }