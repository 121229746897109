var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "header" }, [
    _c("div", { staticClass: "header__container" }, [
      _c("svg", { staticClass: "header__logo" }, [
        _c("use", { attrs: { "xlink:href": "#logo" } })
      ]),
      _c("div", { staticClass: "header__project" }, [
        _vm._v("Administrator console")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }