import Vue from 'vue';
import { LyraList, LyraPagination } from '@lyra_npm/lyra.ui.components';
import formatUserName from 'index/utils/formatUserName';
import formatDate from 'filters/formatDate';
import { getVideosList as getListAPI, deleteVideoItem as deleteItemAPI, deleteAllVideos as deleteAllAPI, } from 'shared/provider/api/content';
export default Vue.extend({
    components: {
        LyraList: LyraList,
        LyraPagination: LyraPagination,
    },
    filters: {
        formatDate: formatDate('DD.MM.YYYY'),
    },
    data: function () {
        return {
            contentType: 'Video',
            isFetching: false,
            columns: [
                { label: 'Author', field: 'owner' },
                { label: 'Publish Date', field: 'publishDate' },
                { label: 'Title', field: 'title' },
                { label: '', field: 'delete', size: 'max-content' },
            ],
            list: {
                items: [],
                pageCount: 0,
                totalCount: 0,
            },
            params: {
                limit: 20,
                pageNumber: 0,
            },
        };
    },
    computed: {
        texts: function () {
            return {
                pageTitle: "Prohibited " + this.contentType + "s",
                deleteItem: {
                    title: "Delete " + this.contentType + "?",
                    success: this.contentType + " has been deleted successfully",
                },
                deleteAll: {
                    title: "Delete all prohibited " + this.contentType.toLowerCase() + "s?",
                    success: "All prohibited " + this.contentType.toLowerCase() + "s has been deleted successfully",
                },
            };
        },
        pagination: function () {
            var pageCount = this.list.pageCount;
            if (!pageCount || pageCount <= 1) {
                return null;
            }
            return {
                current: this.params.pageNumber + 1,
                total: pageCount,
            };
        },
    },
    created: function () {
        this.fetchData();
    },
    methods: {
        getListAPI: getListAPI,
        deleteItemAPI: deleteItemAPI,
        deleteAllAPI: deleteAllAPI,
        fetchData: function () {
            var _this = this;
            if (this.isFetching) {
                return;
            }
            this.isFetching = true;
            this.getListAPI(this.params)
                .then(function (response) {
                _this.list = response;
            })
                .catch(function (e) { return _this.$handleError(e); })
                .finally(function () {
                _this.isFetching = false;
            });
        },
        formatUserName: formatUserName,
        changePage: function (pageNumber) {
            this.params.pageNumber = pageNumber - 1;
            this.fetchData();
        },
        deleteItem: function (item) {
            var _this = this;
            this.$confirm({
                type: 'negative',
                header: this.texts.deleteItem.title,
                submitText: 'Delete',
            })
                .then(function () { return _this.deleteItemAPI(item.id); })
                .then(function () {
                if (_this.list.items.length === 1 && _this.params.pageNumber) {
                    _this.params.pageNumber -= 1;
                }
                _this.fetchData();
                _this.$notify.success(_this.texts.deleteItem.success);
            })
                .catch(function (e) { return e && _this.$handleError(e); });
        },
        deleteAll: function () {
            var _this = this;
            this.$confirm({
                type: 'negative',
                header: this.texts.deleteAll.title,
                submitText: 'Delete',
            })
                .then(function () { return _this.deleteAllAPI(); })
                .then(function () {
                _this.list.items = [];
                _this.$notify.success(_this.texts.deleteAll.success);
            })
                .catch(function (e) { return e && _this.$handleError(e); });
        },
    },
});
