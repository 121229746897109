import Vue from 'vue';
import PortalVue from 'portal-vue';
import VIcon from 'components/VIcon/component.vue';
import { LyraInput, LyraInputDateExpanded, LyraInputFlag, LyraInputPassword, LyraButton, LyraNotify, LyraDialog, LyraSpinner, LyraTooltip, LyraConfirmation, } from '@lyra_npm/lyra.ui.components';
import handleError from 'plugins/handleError';
import { setHttp } from 'shared/utils/http';
import tokenManager from 'shared/utils/tokenManager';
export default (function (config) {
    Vue.prototype.$lyraUiConfig = {
        manualValidation: true,
    };
    Vue.component('VIcon', VIcon);
    Vue.component('LyraInput', LyraInput);
    Vue.component('LyraInputDateExpanded', LyraInputDateExpanded);
    Vue.component('LyraInputPassword', LyraInputPassword);
    Vue.component('LyraInputFlag', LyraInputFlag);
    Vue.component('LyraButton', LyraButton);
    Vue.component('LyraDialog', LyraDialog);
    Vue.component('LyraSpinner', LyraSpinner);
    Vue.component('LyraTooltip', LyraTooltip);
    Vue.use(PortalVue);
    Vue.use(LyraNotify, {
        componentOptions: {
            shownAmount: 1,
            align: 'right',
            timeout: 2000,
            shouldDestroyErrorOnTimeout: true,
            transitionDuration: { enter: 200, leave: 0 },
        },
    });
    Vue.use(LyraConfirmation, {
        props: {
            submitText: 'Ok',
            cancelText: 'Cancel',
            type: 'primary',
        },
    });
    Vue.use(handleError);
    Vue.prototype.$config = config;
    var getHeaders = function (_a) {
        var method = _a.method;
        var headers = new Headers();
        headers.append('Authorization', "Bearer " + tokenManager.get());
        headers.append('Accept-Language', 'en-US');
        if (method !== 'GET') {
            headers.append('Content-Type', 'application/json');
        }
        return headers;
    };
    setHttp({ baseUrl: config.apiUrl, mode: 'cors', getHeaders: getHeaders });
});
