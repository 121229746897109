import { __assign } from "tslib";
import commonMessages from './messages';
export default {
    login: {
        required: true,
        minlength: 3,
        maxlength: 24,
        messages: __assign(__assign({}, commonMessages), { tooShort: 'Login does not meet the requirements. Enter a combination of at least 3 numbers, letters and special character (_-@.)' }),
    },
    description: {
        maxlength: 255,
    },
    password: {
        required: true,
        maxlength: 16,
        minlength: 8,
        messages: __assign(__assign({}, commonMessages), { tooShort: 'Password must be at least 8 characters long' }),
    },
};
