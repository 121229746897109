import { getHttp } from 'shared/utils/http';
export var getList = function (params) { return (getHttp().get({
    path: '/user-api/api/users',
    params: params,
})); };
export var getCount = function () { return getHttp().get({
    path: '/user-api/api/users/count',
}); };
export var getSingle = function (id) { return getHttp().get({
    path: "/user-api/api/users/" + id,
}); };
export var create = function (body) { return (getHttp().post({ path: '/user-api/api/users', body: body })); };
export var edit = function (id, body) { return (getHttp().put({
    path: "/user-api/api/users/" + id,
    body: body,
})); };
export var updateStatus = function (id, action) { return (getHttp().patch({
    path: "/user-api/api/users/" + id + "/" + action,
    body: {},
})); };
export var updatePassword = function (id, body) { return (getHttp().patch({
    path: "/user-api/api/users/" + id + "/setPassword",
    body: body,
})); };
export var getRequestsList = function (params) { return (getHttp().get({
    path: '/user-api/api/users/verification/requests',
    params: params,
})); };
export var getRequestsById = function (id) { return (getHttp().get({ path: "/user-api/api/users/verification/request/" + id })); };
export var processRequest = function (id, action) { return (getHttp().patch({
    path: "/user-api/api/users/verification/request/" + action + "/" + id,
})); };
