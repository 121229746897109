import Vue from 'vue';
import { getCount } from 'shared/provider/api/users';
import VCounter from 'index/components/VCounter/component.vue';
export default Vue.extend({
    components: {
        VCounter: VCounter,
    },
    data: function () {
        return {
            count: 0,
        };
    },
    created: function () {
        var _this = this;
        getCount()
            .then(function (_a) {
            var count = _a.count;
            _this.count = count;
        })
            .catch(this.$handleError);
    },
});
