import { getHttp } from 'shared/utils/http';
export var getRequestsList = function (params) { return (getHttp().get({
    path: '/user-api/api/businessRegistrationRequests',
    params: params,
})); };
export var getRequestById = function (id) { return (getHttp().get({
    path: "/user-api/api/businessRegistrationRequests/" + id,
})); };
export var processRequest = function (options) { return (getHttp().patch({
    path: "/user-api/api/businessRegistrationRequests/" + options.id + "/" + options.action,
})); };
export var getRegisteredList = function (params) { return (getHttp().get({
    path: '/user-api/api/businessProfiles',
    params: params,
})); };
export var getRegisteredById = function (id) { return (getHttp().get({
    path: "/user-api/api/businessProfiles/" + id,
})); };
export var toggleIsActive = function (id) { return (getHttp().patch({
    path: "/user-api/api/businessProfiles/" + id + "/state",
})); };
